//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BranchForm from '@/modules/branch/components/branch-form';

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';

export default {
  name: 'app-branch-form-modal',

  props: ['visible', 'id'],

  components: {
    [BranchForm.name]: BranchForm,
  },

  data() {
    return {
      successDialog: false,
      operation: undefined,
      pinCode: undefined,
    };
  },

  computed: {
    ...mapGetters({
      record: 'branch/form/record',
      findLoading: 'branch/form/findLoading',
      saveLoading: 'branch/form/saveLoading',
      pinCodeLoading: 'branch/form/pinCodeLoading',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isEditing() {
      return !!this.id;
    },
    isAddBranchManuel() {
      return this.operation == 'addBranch' || this.branchType == 'branch'
    },
    isGeneratePin() {
      return this.operation == 'generatePin'
    },
  },
  
  // async created() {
  //   if (this.isEditing) {
  //     await this.doFind(this.id);
  //   } else {
  //     await this.doNew();
  //   }
  // },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },

  methods: {
    ...mapActions({
      doFind: 'branch/form/doFind',
      doNew: 'branch/form/doNew',
      doUpdate: 'branch/form/doUpdate',
      doCreate: 'branch/form/doCreate',
      doGeneratePinCode: 'branch/form/doGeneratePinCode',
    }),
    i18n(code) {
      return i18n(code)
    },
    doCancel() {
      this.dialogVisible = false;
    },

    async doGenerate() {
      // this.pinCode = await this.doGeneratePinCode()
      this.operation = 'generatePin'
    },
    async doSubmit(payload) {
      if (this.isEditing) {
        await this.doUpdate(payload);
        this.$emit('close');
      } else {
        const isCreated = await this.doCreate(payload.values);
        this.$emit('close');
        if (isCreated) {
          this.successDialog = true
        }
      }
    },
  },
};
