//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { BranchModel } from '@/modules/branch/branch-model';
import i18n from '@/vueI18n';
import firebase from 'firebase/app'
import 'firebase/firestore'

const { fields } = BranchModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.address,
  fields.telephone,
  fields.taxFacilityNumber,

  fields.manager,

  fields.isOnlineStore,
  fields.websiteUrl,
  fields.countryId,
  fields.cityId,
  fields.regionId,
]);


export default {
  name: 'app-branch-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      step: 1,
      cities: [],
      regions: [],
      citiesOptions: [],
      regionsOptions: [],
      active: false,
      model: null,
      rules: formSchema.rules(),
      // language: getLanguageCode(),
      unsubscribe:undefined,
    };
  },

  async created() {
    this.model = formSchema.initialValues(this.record || {});
    if (!this.isEditing) {
      this.model.isOnlineStore = false
      this.model.countryId = this.language == 'ar' ? 'مصر' : 'Egypt'
      this.model.manager = {
        name: undefined,
        phoneNumber: undefined,
      }
    }
    
    this.unsubscribe = await firebase.firestore().collection('city').onSnapshot(async () => {
      this.cities = []
      this.regions = []
      const cities = await firebase.firestore().collection("city").get()
      cities.forEach(async (doc) => {
        let city = doc.data()
        city['id'] = doc.id
        city['regions'] = []
        let regions = await firebase.firestore().collection("city").doc(doc.id).collection("regions").get()
        regions.forEach(async (region) => {
          let regionInCity = region.data()
          regionInCity['id'] = region.id
          city['regions'].push(regionInCity)
          regionInCity['city'] = city.name
          this.regions.push(regionInCity)
        })
        this.cities.push(city)
      })
      // this.loading = false
    })
  },
  beforeDestroy(){
    if(this.unsubscribe) this.unsubscribe()
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      is_screen_xs: 'layout/is_screen_xs',
    }),

    language(){
      return i18n.locale;
    },

    fields() {
      return fields;
    },
    citiesList() {
      return this.cities.map(item => {
        return {
          label: item.name[this.language],
          value: item.id, 
        }
      })
    },
    regionsInCityList() {
      let regions = []
      let selected_city = this.cities.filter(city => city.id == this.model.cityId)
      
      if (selected_city.length) {
        regions = selected_city[0].regions.map(region => {
          return {
            label: region.name[this.language],
            value: region.id, 
          }
        })
      }
      return regions
    },
  },

  methods: {
    ...mapActions({
      // doFetchSparePart: 'sparePart/list/doFetch',
    }),
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
      this.$emit('cancel');
    },

    OnClear() {
      this.model.branchParts = []
    },
    filterGovernorateFn (val, update) {
      if (val === '') {
        update(() => {
          this.citiesOptions = this.citiesList

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.citiesOptions = this.citiesList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    filterCitiesFn (val, update) {
      if (val === '') {
        update(() => {
          this.regionsOptions = this.regionsInCityList

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.regionsOptions = this.regionsInCityList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },

    async doSubmit() {
      if (this.step < 3) {
        return this.$refs.stepper.next()
      }

      const { id, ...values } = formSchema.cast(this.model);
      return this.$emit('submit', {
        id,
        values,
      });
    },
  },
};
