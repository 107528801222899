//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BranchListTable from '@/modules/branch/components/branch-list-table.vue';
import BranchFormModal from '@/modules/branch/components/branch-form-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import { BranchPermissions } from '@/modules/branch/branch-permissions';
import firebase from 'firebase/app';
import 'firebase/firestore'
import i18n from '@/vueI18n'

export default {
  name: 'app-branch-list-page',

  components: {
    [BranchListTable.name]: BranchListTable,
    [BranchFormModal.name]: BranchFormModal,
  },
  data() {
    return {
      isListView: false,
      modalVisible: false,
      dialogVisible: false,
      unsubscribe:undefined
    }
  },
  computed: {
    ...mapGetters({
      rows: 'branch/list/rows',
      count: 'branch/list/count',
      loading: 'branch/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new BranchPermissions(this.currentUser).create;
    },
    language(){
      return i18n.locale
    }
  },
  beforeDestroy(){
    if(this.unsubscribe) this.unsubscribe()
  },
  async created() {
    this.unsubscribe = await firebase.firestore().collection('branch').onSnapshot(async querySnapshot => {
       console.log(`Received query snapshot of size ${querySnapshot.size}`);
      this.doFilterInBackground();
    }, err => {
       console.log(`Encountered error: ${err}`);
    });
  },
  async mounted() {
    await this.doFilter();
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.branch') 
    },
  },
  methods: {
    ...mapActions({
      doFetch: 'branch/list/doFetch',
      doFetchInBackground: 'branch/list/doFetchInBackground',
    }),
     i18n(key, args) {
      return this.$t(key, args);
    },

    // displaySwitch(view) {
    //   switch (view) {
    //     case 'card':
    //       this.isListView = false
    //       break;
    //     case 'list':
    //       this.isListView = true
    //       break;
    //     default:
    //       break;
    //   }
    // },
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
  
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = {}
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = {}
      return this.doFetchInBackground({
        filter,
      });
    },
  },

};
