//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { BranchModel } from '@/modules/branch/branch-model';
import TableViewPage from '@/shared/components/table-view-page.vue';
import BranchFormModal from '@/modules/branch/components/branch-form-modal.vue';
import { BranchPermissions } from '@/modules/branch/branch-permissions';
import i18n  from '@/vueI18n';
import moment from 'moment';

const { fields } = BranchModel;

export default {
  name: 'app-branch-list-table',

  components: {
    [TableViewPage.name]: TableViewPage,
    [BranchFormModal.name]:BranchFormModal
  },

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      filter: '',
      modalVisible:false,
      columns: [
        {
          name: 'name',
          field: 'name',
          label: 'entities.seller.fields.name',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'telephone',
          field: 'telephone',
          label: 'entities.seller.fields.telephone',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'managerName',
          field: 'managerName',
          label: 'entities.seller.fields.managerName',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'managerPhone',
          field: 'managerPhone',
          label: 'entities.seller.fields.managerPhone',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'taxFacilityNumber',
          field: 'taxFacilityNumber',
          label: 'entities.seller.fields.taxFacilityNumber',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'countryId',
          field: 'countryId',
          label: 'entities.seller.fields.countryId',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'cityId',
          field: 'cityId',
          label: 'entities.seller.fields.cityId',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'regionId',
          field: 'regionId',
          label: 'entities.seller.fields.regionId',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'action',
          field: 'action',
          label:'common.actions',
          align: 'center',
        },
      ],
      // language: i18n.locale,
      dialogVisible: false,
      dialogType: '',
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
    };
  },
  computed: {
    ...mapGetters({
      rows: 'branch/list/rows',
      count: 'branch/list/count',
      // pagination: 'branch/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: 'branch/list/loading',
      backgroundLoading: 'branch/list/backgroundLoading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'branch/destroy/loading',
      is_screen_xs: 'layout/is_screen_xs',
    }),

    hasPermissionToEdit() {
      return new BranchPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new BranchPermissions(this.currentUser)
        .destroy;
    },

    fields() {
      return fields;
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    },
    language(){
      return i18n.locale
    }
  },
  methods: {
    ...mapActions({
      doChangeSort: 'branch/list/doChangeSort',
      doChangePaginationCurrentPage: 'branch/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'branch/list/doChangePaginationPageSize',
      doMountTable: 'branch/list/doMountTable',
      doDestroy: 'branch/destroy/doDestroy',
      // doFetchInBackground: 'branch/list/doFetchInBackground',
      // doFetch: 'branch/list/doFetch',
    }),
    // i18n(code, args) {
    //   return i18n(code, args)
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      return BranchModel.presenter(row, fieldName);
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : '';
    },
    presenterMap(row, fieldName) {
      const val = BranchModel.presenter(row, fieldName);
      return val[this.language]
    },
    presenterDate(row, fieldName) {
      const val = BranchModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : ''; 
    },
    presenterDay(row, fieldName) {
      return moment(BranchModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = BranchModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },
    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogVisible = true
          this.modalVisible = true
          break;
        default:
          break;
      }
      this.dialogVisible = true
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.doDestroy(id);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },
  },
};
